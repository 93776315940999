<template>
  <div class="live-big-data">
    <container-with-title
      title="敬请期待"
      description="找表现最好的直播商品找头部直播间&行业新星找最热门的在播直播间直播间数据分析复盘"
      operate-text=""
      :img-src="require('../../assets/images/home/live.png')"
    />
  </div>
</template>

<script>
import ContainerWithTitle from "../../components/ContainerWithTitle";

export default {
  components: {
    ContainerWithTitle,
  },
};
</script>

<style lang="less" scoped>
.live-big-data {
  background: #d3e0ff;
  flex: 1;
  padding: 50px 0;
}
</style>
